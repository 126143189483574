import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortFolio from "../components/shared/portfolio/portfolio"

import "../styles/games.scss"
import "../styles/game-page.scss"
import "../styles/main-page.scss"
import "../styles/publishing.scss"

import StickyNavigation from "../components/shared/sticky-navigation/sticky-navigation"

// Videos
import publishing_video from "../videos/publishing.mp4"

class HomePage extends React.Component {

  render(){

    return(
      <Layout>

        <SEO title="Full-Scale Publishing"/>
        <Link to="/" className="logo" activeClassName="-active">Super!com</Link>
        <h2 className="title">Publishing</h2>
        

        <div className="main-page">

          <div className="scroll-arrow"></div>
        
          <section className="main-page__hero">

            <div className="main-page__hero__video">
              <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                <source src={publishing_video} type="video/mp4"/>
              </video>
            </div>

            <div className="center">

              <h2 className="main-page__hero__title">
                <span className="main-page__hero__title__link">We Take</span> Your Vision<br/> 
                to the Next <span className="main-page__hero__title__link">Level</span>
              </h2>
              <p className="main-page__hero__message">
                Full-Scale Publishing is a comprehensive service package combining<br/> broad and effective marketing support with in-depth project consultation.
              </p>

              <div className="games__promo__button">
                <button className="games__promo__button__link" data-role="call-to-action">
                  <span className="games__promo__button__link__text">Submit your project</span>
                </button>
                <div className="games__promo__button__shadow"></div>
              </div>
            
            </div>

          </section>

        </div>


        <div className="main-content -no-background">


          <section className="main-page-section -gradient-background -type-1">

            <div className="center">

              <h4 className="main-page-section__title">
                <i></i>
                <span className="main-page-section__title__text">
                  Unlock the Power <br/>
                  of Full-Scale Publishing
                </span>
              </h4>

            </div>

            <div className="center -thin">

              <div className="main-page-section__content">
              
                <div className="multiply -centered">
                  <div className="multiply__line">
                    <div className="multiply__item -big-icon">
                      <div className="multiply__item__illustration -person-computer"></div>
                      <p className="multiply__item__text">
                        Comprehensive publishing support, from digital ads to retail distribution
                      </p>
                    </div>
                    <div className="multiply__item -big-icon">
                      <div className="multiply__item__illustration -person-clocks"></div>
                      <p className="multiply__item__text">
                        An in-house marketing team that stays in contact 24/7
                      </p>
                    </div>
                  </div>
                  <div className="multiply__line">
                    <div className="multiply__item -big-icon">
                      <div className="multiply__item__illustration -checkmarks-gamepad"></div>
                      <p className="multiply__item__text">
                        All major platforms, including PC, PlayStation, Xbox, and Nintendo Switch
                      </p>
                    </div>
                    <div className="multiply__item -big-icon">
                      <div className="multiply__item__illustration -checkmarks-globus"></div>
                      <p className="multiply__item__text">
                        A wide network of contractors across the USA, Europe, and Asia
                      </p>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </section>


          <section className="main-page-section -no-margin-top -gradient-background -type-2 -gamepad-background">

            <div className="center">

              <h4 className="main-page-section__title -no-lines">
                <span className="main-page-section__title__text">
                  Unleash Your Creative Freedom <br/>
                  Let Super Handle the Growth
                </span>
              </h4>

              <p className="main-page-section__message -wide">
                We offer multiple degrees of excellence. A squad of experienced marketing jedi, producers, game analysts, and testers are set in place to get your game rolling.
              </p>

            </div>

            <div className="center -thin">

              <div className="main-page-section__content">
              
                  <div className="indicators">

                    <div className="indicators__line">
                      <div className="indicators__line__item -globus-dots">
                        Global marketing superiority. From the USA to Europe and Asia
                      </div>
                      <div className="indicators__line__item -hands">
                        Direct contact with Steam, GOG, Nintendo, Microsoft, Sony, the Epic Game Store, and Unreal
                      </div>
                    </div>

                    <div className="indicators__line">
                      <div className="indicators__line__item -desk-chart">
                        A marketing guarantee to ensure that your game release journey goes smoothly
                      </div>
                      <div className="indicators__line__item -person-lines">
                        Featuring by regional game influencers on popular streaming platforms
                      </div>
                    </div>

                    <div className="indicators__line">
                      <div className="indicators__line__item -baloons-persons">
                        Our community management team can work in more than five languages
                      </div>
                      <div className="indicators__line__item -ads">
                        Digital ads on social media, traffic buying from platformholders
                      </div>
                    </div>

                    <div className="indicators__line">
                      <div className="indicators__line__item -baloons-play">
                        Create superior promotional videos and CGI release trailers
                      </div>
                      <div className="indicators__line__item -magic-wind">
                        Outreach that has been perfected across major gaming media platforms
                      </div>
                    </div>

                  </div>

                  <button to="/" className="simple-button" id="show-more">Show More</button>
              
                  <div className="indicators -hidden" id="indicators-hidden">

                    <div className="indicators__line">
                      <div className="indicators__line__item -a-b">
                        Get in the lab. We provide QA, LQA, VO, and an internal perfection testing ground
                      </div>
                      <div className="indicators__line__item -chess">
                        Game positioning, competitor research, and market strategies
                      </div>
                    </div>

                    <div className="indicators__line">
                      <div className="indicators__line__item -person-offline">
                        Facilitation of offline events and support
                      </div>
                      <div className="indicators__line__item -list">
                        Publishing for post-release ancillary rights
                      </div>
                    </div>

                    <div className="indicators__line">
                      <div className="indicators__line__item -baloons-persons-squared">
                        Our producers and engineers provide feedback to enhance your game
                      </div>
                      <div className="indicators__line__item -circle-arrow">
                        Sales in retail stores via local distributors and warehouses
                      </div>
                    </div>

                    <div className="indicators__line">
                      <div className="indicators__line__item -cloud">
                        Communication with platforms and stores, submission of builds
                      </div>
                      <div className="indicators__line__item -hands-person">
                        Humans can be a pivotal resource. We source superior talent for your studio
                      </div>
                    </div>

                  </div>

              </div>

            </div>

          </section>


          <section className="main-page-section -no-margin-top -gradient-background -type-2">

            <div className="center">

              <h4 className="main-page-section__title">
                <i></i>
                <span className="main-page-section__title__text">
                  Pillars of Our <span className="nowrap">Approach</span>
                </span>
              </h4>

              <p className="main-page-section__message -wide">
                We handle all types of games, leaning toward action games that fuse originality and popular game mechanics into a well-rounded package.
              </p>

            </div>

            <div className="center -thin">

              <div className="main-page-section__content">
              
                  <div className="pillars">

                    <div className="pillars__item -gamepad">
                      <h5 className="pillars__item__title"><span>Playtests</span></h5>
                      <p className="pillars__item__text">
                        We're passionate about games. We don't just supply resources: our team is the resource. We test your game at every phase of development and offer constructive feedback to fine tune the player experience so they get the best out of your vision.
                      </p>
                    </div>

                    <div className="pillars__item -brilliant">
                      <h5 className="pillars__item__title"><span>Transparency</span></h5>
                      <p className="pillars__item__text">
                        We make communication a priority. Our team is always available to share feedback, engage in constructive meetings, talk through obstacles, and discuss the optimal way to move forward with a project.
                      </p>
                    </div>

                    <div className="pillars__item -person-circle">
                      <h5 className="pillars__item__title"><span>360° support</span></h5>
                      <p className="pillars__item__text">
                        We offer a full-scale publishing service that includes eveything a modern premium publisher can offer: from direct contact with game stores, to submission to streamers and bloggers.
                      </p>
                    </div>

                  </div>

              </div>

            </div>

          </section>


          <section className="main-page-section -no-margin-top -gradient-background -type-3">

            <div className="center">

              <h4 className="main-page-section__title">
                <i></i>
                <span className="main-page-section__title__text">
                  Our <span className="nowrap">Games</span>
                </span>
              </h4>

            </div>

            <div className="center -thin">

              <div className="main-page-section__content">
              

                <PortFolio></PortFolio>


              </div>

            </div>

          </section>


          <section className="main-page-section -no-margin-top -gradient-background -type-2">

            <div className="center">

              <h4 className="main-page-section__title">
                <i></i>
                <span className="main-page-section__title__text">
                  Super Core Values
                </span>
              </h4>

            </div>

            <div className="center -thin">

              <div className="main-page-section__content">
              
                <div className="multiply -centered -tripple">
                  <div className="multiply__line">
                    <div className="multiply__item">
                      <div className="multiply__item__illustration -brilliant"></div>
                      <h5 className="multiply__item__title">
                        Transparency
                      </h5>
                      <p className="multiply__item__text">
                        We play with purpose. Our team tests games, which allows us to provide constructive and helpful feedback. We openly communicate with teams and provide support in order to make your game the best it can be.
                      </p>
                    </div>
                    <div className="multiply__item">
                      <div className="multiply__item__illustration -hands-chart"></div>
                      <h5 className="multiply__item__title">
                        Data for a purpose
                      </h5>
                      <p className="multiply__item__text">
                        We love to crunch numbers. All our reports are supported by a detailed breakdown of the game's sales and expenditure. You can easily keep track of your cash flow.
                      </p>
                    </div>
                    <div className="multiply__item">
                      <div className="multiply__item__illustration -hands-gear"></div>
                      <h5 className="multiply__item__title">
                        Responsibility
                      </h5>
                      <p className="multiply__item__text">
                        With great power comes great responsibility. We fully embrace our duty in bringing the best we have to offer. We give our all to ensure that we remain accountable for our role in enhanсing your potential as a development team. Period.
                      </p>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </section>


        </div>


        <div className="main-page">

          <div className="scroll-arrow"></div>

          <section className="main-page__hero -no-overlay">

            <div className="center">

              <h2 className="main-page__hero__title -wide-letter-spacing">
                <span className="main-page__hero__title__link">Become</span><br className="main-page__hero__title__br"/> Super Powerful
              </h2>
              <p className="main-page__hero__message -min-margin-top">
                It doesn't take a radioactive spider or a freak accident to get Super powers.
              </p>

              <div className="games__promo__button">
                <button className="games__promo__button__link" data-role="call-to-action">
                  <span className="games__promo__button__link__text">Submit your project</span>
                </button>
                {/* <button className="b24-web-form-popup-btn-3">Button name</button> */}
                <div className="games__promo__button__shadow"></div>
              </div>

              <p className="main-page__hero__button-message">
                Just click the button and get the resources you need to have an edge on your competition.
              </p>
            
            </div>

          </section>

        </div>


        {/* <div className="main-content -gradient-background">

          <div className="center -thin">

            <div className="main-page-section__content -no-paddings">

              <div className="multiply -two">
                <div className="multiply__line">
                  <Link to="/publishing/" className="multiply__item">
                    <h5 className="multiply__item__title">
                      Fast-Track Publishing
                    </h5>
                    <p className="multiply__item__text">
                      It's dangerous to go alone. Super lets you take everything you need and secure peace of mind when publishing your game solo.
                    </p>
                    <div className="multiply__item__button">Learn More</div>
                    <div className="multiply__item__illustration -socials"></div>
                  </Link>
                  <Link to="/fund/" className="multiply__item">
                    <h5 className="multiply__item__title">
                      Funding
                    </h5>
                    <p className="multiply__item__text">
                      Get your company souped up with our liquidity offers. We offer equity financing over up to $10M for studios who want to turn their business into a success story.
                    </p>
                    <div className="multiply__item__button">Learn More</div>
                    <div className="multiply__item__illustration -speedometer"></div>
                  </Link>
                </div>
              </div>

            </div>

          </div>

        </div> */}

      </Layout>
    );

  }

}

export default HomePage
